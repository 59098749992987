<template>
  <div class="active--switch">
    <label :for="`publishToggle${listingUuid}`" class="custom--switch">
      <input
        :id="`publishToggle${listingUuid}`"
        type="checkbox"
        :checked="false"
        @click.prevent="inputHandler"
      />
      <span class="slider"></span>
    </label>
    <label :for="`publishToggle${listingUuid}`" class="switch--label">
      {{ $t('general.btn.notPublish') }}
    </label>
  </div>
</template>

<script>
export default {
  name: 'publish-toggle',
  props: {
    listingUuid: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      default: null,
    },
    fromMylisting: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      checkConfirmServiceFeeTc: false,
    };
  },
  computed: {
    actionLoading: {
      get() {
        return this.$store.state.myListing.actionLoading;
      },
      set(val) {
        this.$store.commit('myListing/SET_ACTION_LOADING', val);
      },
    },
    bankAccountCheckPayload: {
      get() {
        return this.$store.state.v2.profile.bankAccountCheckPayload;
      },
      set(val) {
        this.$store.commit('v2/profile/SET_BANK_ACC_CHECK_PAYLOAD', val);
      },
    },
    checkBankAccountDone: {
      get() {
        return this.$store.state.myListing.checkBankAccountDone;
      },
      set(val) {
        this.$store.commit('myListing/SET_CHECK_BACK_ACCOUNT_DONE', val);
      },
    },
  },
  watch: {
    checkBankAccountDone(val) {
      if (val && this.bankAccountCheckPayload === this.listingUuid) {
        this.inputHandler();
      }
    },
    checkConfirmServiceFeeTc(val) {
      if (val) {
        this.inputHandler();
      }
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async inputHandler(_) {
      try {
        this.actionLoading = true;

        const canPublishListing = await this.$store.dispatch(
          'myListing/checkPublishListing',
          this.listingUuid,
        );
        // this.actionLoading=false;
        if (canPublishListing) {
          // this.actionLoading=true;
          let resultCheck = true;
          if (!this.checkBankAccountDone) {
            resultCheck = await this.$store.dispatch('v2/profile/checkUserCanAcceptBooking');
            if (resultCheck == false) {
              this.$modal.show('modal-input-bank-account', {
                showNotNowBtn: true,
                postDoneAction: () => {
                  this.checkBankAccountDone = true;
                },
                postCancelAction: () => {
                  this.checkBankAccountDone = true;
                },
              });
              this.bankAccountCheckPayload = this.listingUuid;
              return;
            }
          }
          // this.actionLoading=false;
          // this.actionLoading=true;
          let resultCheckConfirmTc = true;
          if (!this.checkConfirmServiceFeeTc) {
            resultCheckConfirmTc = await this.$store.dispatch(
              'v2/profile/checkUserConfirmServiceFeeTc',
            );
          }
          console.log('resultCheckConfirmTc', resultCheckConfirmTc);
          if (!resultCheckConfirmTc) {
            this.$modal.show('modal-confirm-service-fee-tc', {
              postDoneAction: () => {
                this.checkConfirmServiceFeeTc = true;
              },
            });
            return;
          }
          const publishSuccess = await this.$store.dispatch(
            'myListing/publishListing',
            this.listingUuid,
          );
          // this.actionLoading=false;
          if (publishSuccess) {
            if (this.fromMylisting) {
              this.$store.dispatch('myListing/getListings', this.page);
              this.$store.dispatch('myListing/fetchSearchListingOptions');
              this.$store.dispatch('myListing/getCategoryCountData');
            }
            await this.$swal(
              this.$i18n.t('general.success'),
              this.$i18n.t('booking.swal.draft.successPublish'),
              'success',
            );
            this.$emit('actionDone');
          }
        } else {
          this.$swal({
            title: this.$i18n.t('mylisting.failedPublishTitle'),
            text: this.$i18n.t('mylisting.failedPublishMessage'),
            type: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$i18n.t('mylisting.completeData'),
            cancelButtonText: this.$i18n.t('mylisting.cancel'),
            allowOutsideClick: false,
          }).then((result) => {
            if (result.value) {
              this.$router.push({
                path: '/listing/edit/' + this.listingUuid,
              });
            }
          });
        }
      } finally {
        this.actionLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
